import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferBody, type InferQuery } from '@/utils/ts-rest';

export type CreateMaintenanceTaskServiceOptions = {
  tsRestClient: TsRestClient;
};

export const createMaintenanceTaskService = ({
  tsRestClient
}: CreateMaintenanceTaskServiceOptions) => {
  return {
    getAll(query: InferQuery<typeof contract.maintenanceTask.all>) {
      return apiHandler(tsRestClient.maintenanceTask.all, { query });
    },

    create(body: InferBody<typeof contract.maintenanceTask.create>) {
      return apiHandler(tsRestClient.maintenanceTask.create, { body });
    },

    delete(taskId: string) {
      return apiHandler(tsRestClient.maintenanceTask.delete, {
        params: { taskId }
      });
    }
  };
};
