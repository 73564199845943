import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferQuery
} from '@/utils/ts-rest';

export const createBleTagService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getAll(query: InferQuery<typeof contract.bleTag.all>) {
      return apiHandler(tsRestClient.bleTag.all, { query });
    },

    addAssociation({
      tagId,
      ...body
    }: InferFlatRequest<typeof contract.bleTag.addAssociation>) {
      return apiHandler(tsRestClient.bleTag.addAssociation, {
        params: { tagId },
        body
      });
    },

    removeAssociation({
      tagId
    }: InferFlatRequest<typeof contract.bleTag.removeAssociation>) {
      return apiHandler(tsRestClient.bleTag.removeAssociation, {
        params: { tagId }
      });
    },

    export(opts: InferBody<typeof contract.bleTag.export>) {
      return apiHandler(tsRestClient.bleTag.export, { body: opts });
    }
  };
};
