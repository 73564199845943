import { contract } from '@samsys/shared';
import type { HttpService } from './http.service';
import type { FetchError } from 'ofetch';
import { config } from '@/config';
export type CreateTsRestServiceOptions = { httpService: HttpService };
import { initClient } from '@ts-rest/core';

export type TsRestClient = ReturnType<typeof createTsRestService>;
export const createTsRestService = ({
  httpService
}: CreateTsRestServiceOptions) => {
  return initClient(contract, {
    baseUrl: config.API_URL,
    baseHeaders: {},
    credentials: 'include',
    jsonQuery: true,
    api: async args => {
      try {
        const resp = await httpService.fetch.raw(args.path, {
          method: args.method,
          headers: args.headers,
          body: args.rawBody as any,
          query: args.rawQuery as any,
          credentials: args.credentials,
          signal: args.signal
        });
        return {
          status: resp.status,
          headers: resp.headers,
          body: resp._data
        };
      } catch (e) {
        console.error(e);
        const err = e as FetchError;
        return {
          status: err.status ?? 500,
          body: err.data,
          headers: err.response?.headers ?? new Headers()
        };
      }
    }
  });
};
