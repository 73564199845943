import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferFlatRequest,
  type InferBody
} from '@/utils/ts-rest';

export const createCanbusValuesConfigService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getByCounter(
      params: InferFlatRequest<typeof contract.canbusValuesConfig.byCounter>
    ) {
      return apiHandler(tsRestClient.canbusValuesConfig.byCounter, { params });
    },

    postRequest(body: InferBody<typeof contract.canbusValuesConfig.request>) {
      return apiHandler(tsRestClient.canbusValuesConfig.request, { body });
    },

    postWrite(body: InferBody<typeof contract.canbusValuesConfig.write>) {
      return apiHandler(tsRestClient.canbusValuesConfig.write, { body });
    }
  };
};
