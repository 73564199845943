import type { ApiQueryKey } from '@/composables/useApi';
import type { AnyObject } from '@samsys/shared';
import type { MaybeRef } from '@/types/utils';
import { createQueryKeyStore } from '@lukemorales/query-key-factory';

type ApiQueryKeyGetterFn = (...args: any[]) => ApiQueryKey<AnyObject>;

export const QUERY_KEY_TOPICS = {
  USER: 'user',
  MACHINE: 'machine'
} as const;

export const queryKeys = {
  ME: ['me'],
  PING: ['ping'],
  FEATURE_FLAGS: ['feature flags'],

  SIGNUP: ['signup'],
  LOGIN: ['login'],
  LOGOUT: ['logout'],
  ASK_FOR_PASSWORD_RESET: ['ask for password reset'],
  RESET_PASSWORD: ['reset password'],

  CREATE_VIRTUAL_USER: ['create virtual user'],
  RESEND_INVITE_EMAIL: ['resend invite email'],
  EDIT_USER_RIGHTS: ['edit user rights'],
  EDIT_USER_ROLES: ['edit user roles'],
  EDIT_USER_FAVORITE_DASHBOARDS: ['edit user favorite dashboards'],
  USER_BY_ID: (userId: MaybeRef<string>) => [
    'user by id',
    computed(() => ({ userId: unref(userId) }))
  ],
  USERS_LIST: <T>(filters: MaybeRef<T>) => [
    'users list',
    computed(() => unref(filters))
  ],
  DELEGATED_USERS_LIST: <T>(filters: MaybeRef<T>) => [
    'delegated users list',
    computed(() => unref(filters))
  ],

  MACHINE_CATEGORIES: ['machine categories'],
  MACHINE_BRANDS: ['machine brands'],
  MACHINE_COUNT_BY_CATEGORY: ['machine count by category'],
  MACHINES_LIST: <T>(filters: MaybeRef<T>) => [
    'machines list',
    computed(() => unref(filters))
  ],
  MACHINES_WITH_ASSOCIATION_LIST: <T>(filters: MaybeRef<T>) => [
    'machines with associations list',
    computed(() => unref(filters))
  ],
  MACHINE_GEOLOC: (machineId: MaybeRef<string>) => [
    'machine geoloc',
    computed(() => ({ machineId: unref(machineId) }))
  ],
  MACHINE_GEOLOC_BATCH: (machineIds: MaybeRef<string[]>) => [
    'machine geoloc batch',
    computed(() => ({ machineIds: unref(machineIds) }))
  ],
  MACHINE_BY_ID: (machineId: MaybeRef<string>) => [
    'machine by id',
    computed(() => ({ machineId: unref(machineId) }))
  ],

  CREATE_FIELD: ['create field'],
  FIELDS_BY_USER_IDS: (userIds: MaybeRef<string[]>) => [
    'fields by user ids',
    computed(() => ({ userIds: unref(userIds) }))
  ],

  CREATE_ACTIVITY_BOOKING: ['create activity booking'],
  UPDATE_ACTIVITY_BOOKING: ['update activity booking'],
  VALIDATE_ACTIVITY_BOOKING: ['validate activity booking'],
  DELETE_ACTIVITY_BOOKING: ['delete activity booking'],
  ACTIVITY_BOOKINGS_LIST: <T>(filters: MaybeRef<T>) => [
    'activity bookings list',
    computed(() => unref(filters))
  ],
  ACTIVITY_BOOKINGS_CONCLICTS: <T>(dto: MaybeRef<T>) => [
    'activity bookings conflicts',
    computed(() => unref(dto))
  ],

  DATABOX_LIST: <T>(dto: MaybeRef<T>) => [
    'databox list',
    computed(() => unref(dto))
  ],
  DATABOX_BY_ID: (databoxId: MaybeRef<string>) => [
    'databox by id',
    computed(() => ({ databoxId: unref(databoxId) }))
  ],

  DATABOX_ASSOCIATION_ELLIGIBLE_MACHINES: <T>(dto: MaybeRef<T>) => [
    'databox association elligible machines',
    computed(() => unref(dto))
  ],
  DATABOX_ASSOCIATION_LIST: <T>(dto: MaybeRef<T>) => [
    'databox association list',
    computed(() => unref(dto))
  ],
  UPSERT_DATABOX_ASSOCIATION: ['upsert databox association'],
  DELETE_DATABOX_ASSOCIATION: ['delete databox association'],
  MANAGE_DATABOX_ASSOCIATION_SPLITTING: ['delete databox association'],

  REFUSE_CLUSTER_INVITE: ['refuse cluster invite'],
  ACCEPT_CLUSTER_INVITE: ['accept cluster invite'],
  CLUSTER_LIST: <T>(dto: MaybeRef<T>) => [
    'cluster list',
    computed(() => unref(dto))
  ],

  MARK_NOTIFICATIONS_AS_SEEN: ['mark notifications as seen'],
  NOTIFICATION_LIST: <T>(dto: MaybeRef<T>) => [
    'notification list',
    computed(() => unref(dto))
  ],

  ACTIVITY_LIST: <T>(dto: MaybeRef<T>) => [
    'activity list',
    computed(() => unref(dto))
  ],
  ACTIVITY_DETAILS: <T>(dto: MaybeRef<T>) => [
    'activity details',
    computed(() => unref(dto))
  ],

  ACTIVITY_STATS: <T>(dto: MaybeRef<T>) => [
    'activity stats',
    computed(() => unref(dto))
  ],

  FAVORITE_FILTERS_BY_KEY: <T>(dto: MaybeRef<T>) => [
    'favorite_filters',
    computed(() => unref(dto))
  ],

  ADD_FAVORITE_FILTER: ['add favorite filter'],
  REMOVE_FAVORITE_FILTER: ['remove favorite filter'],

  DATADEF_BY_DATABOX_ID: <T>(dto: MaybeRef<T>) => [
    'datadef by databox id',
    computed(() => unref(dto))
  ],

  DATABOX_TSDATA: <T>(dto: MaybeRef<T>) => [
    'databox tsdata',
    computed(() => unref(dto))
  ],

  WIDGET_LIST: <T>(dto: MaybeRef<T>) => [
    'widget list',
    computed(() => unref(dto))
  ],
  SHARED_WIDGET_LIST: <T>(dto: MaybeRef<T>) => [
    'shared widget list',
    computed(() => unref(dto))
  ],
  WIDGET_BY_ID: <T>(dto: MaybeRef<T>) => [
    'widget by id',
    computed(() => unref(dto))
  ],
  WIDGET_DATA: <T>(dto: MaybeRef<T>) => [
    'widget data',
    computed(() => unref(dto))
  ],
  CREATE_WIDGET: ['create widget'],
  UPDATE_WIDGET: ['update widget'],
  DELETE_WIDGET: ['delete widget'],

  TSDATADEF_BY_WIDGET_TARGETS: <T>(dto: MaybeRef<T>) => [
    'tsdatadef by databox ids',
    computed(() => unref(dto))
  ],

  DASHBOARD_LIST: <T>(dto: MaybeRef<T>) => [
    'dashboard list',
    computed(() => unref(dto))
  ],
  DASHBOARD_BY_ID: <T>(dto: MaybeRef<T>) => [
    'dashboard by id',
    computed(() => unref(dto))
  ],
  CREATE_DASHBOARD: ['create dashboard'],
  UPDATE_DASHBOARD: ['update dashboard'],
  DELETE_DASHBOARD: ['delete dashboard'],
  FAVORITE_DASHBOARDS: ['favorite dashboards'],
  CLONE_DASHBOARD: ['clone dashboard']
} satisfies Record<string, ApiQueryKey<AnyObject> | ApiQueryKeyGetterFn>;

export const queryKeysV2 = createQueryKeyStore({
  users: {
    me: null
  }
});
