import { apiHandler, type InferFlatRequest } from '@/utils/ts-rest';
import type { HttpService } from './http.service';
import type { contract, FileExportResponse } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';

export type CreateFileExportServiceOptions = {
  httpService: HttpService;
  tsRestClient: TsRestClient;
};

export const createFileExportService = ({
  httpService,
  tsRestClient
}: CreateFileExportServiceOptions) => {
  return {
    getAll(query: InferFlatRequest<typeof contract.fileExport.all>) {
      return apiHandler(tsRestClient.fileExport.all, { query });
    },

    async download(fileExport: FileExportResponse) {
      const blob = await httpService.get(`/api/v2/exports/${fileExport.id}`, {
        responseType: 'blob'
      });

      const aElement = document.createElement('a');
      aElement.setAttribute('download', fileExport.filename);
      const href = URL.createObjectURL(blob as Blob);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
      aElement.remove();
    }
  };
};
