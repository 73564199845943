<script setup lang="ts">
import { isString } from '@samsys/shared';
import type { Component as VueComponent } from 'vue';

const layoutMap = new Map<string, VueComponent>();
layoutMap.set(
  'default',
  defineAsyncComponent(() => import('@/components/layouts/default.vue'))
);
layoutMap.set(
  'unauthenticated',
  defineAsyncComponent(() => import('@/components/layouts/unauthenticated.vue'))
);

const route = useRoute();
const layoutComponent = computed(() => {
  const key = isString(route.meta.layout) ? route.meta.layout : 'default';
  const layout = layoutMap.get(key);

  return layout || layoutMap.get('default');
});
</script>

<template>
  <component :is="layoutComponent">
    <slot />
  </component>
</template>
