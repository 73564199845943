// this is a fix because some libraries rely on a globally defined  "global" variable
// older bundler like webpack define it, but not vite.
import { Buffer } from 'buffer/';
window.global ||= window;
// @ts-ignore hack for making shpjs library work
window.Buffer = Buffer;
// @ts-ignore hack for making shpjs library work
window.buffer = Buffer;
// @ts-ignore hack for making shpjs library work
window.nodebuffer = Buffer;

import '@/styles/global.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { routes } from 'vue-router/auto-routes';
import { createI18n } from 'vue-i18n';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import messages from '@intlify/unplugin-vue-i18n/messages';
import { createApiClient } from './utils/createApi';
import { API_INJECTION_KEY } from './composables/useApi';
import { createPinia } from 'pinia';
import { validateConfig } from './config';
import { queryKeys } from './utils/queryKeys';
import { queryClient } from './utils/queryClient';
import { enableCache, disableCache } from '@iconify/vue';
import { getRedirectUrl } from '@/composables/useAuthGuard';
import { makeZodI18nMap } from 'zod-vue-i18n';
import { z } from 'zod';
import type { AnyObject } from '@samsys/shared';

enableCache('session');
disableCache('local');

const extractKeys = (obj: AnyObject, prefix = ''): string[] => {
  return Object.entries(obj).flatMap(([key, value]) => {
    if (typeof value === 'object') {
      return extractKeys(value, `${prefix}${key}.`);
    }

    return `${prefix}${key}`;
  });
};

validateConfig();
routes.push({ path: '/', redirect: '/dashboard' });

async function main() {
  const router = createRouter({
    history: createWebHistory(),
    routes
  });
  const i18nOptions = {
    locale: localStorage.getItem('samsys-lang') ?? 'fr',
    messages: {
      ...messages,
      dev: Object.fromEntries(extractKeys(messages!.fr as any).map(k => [k, k]))
    } as unknown as typeof messages
  };
  const i18n = createI18n<false, typeof i18nOptions>(i18nOptions);
  z.setErrorMap(makeZodI18nMap(i18n));

  const apiClient = createApiClient({
    getLang: () => i18n.global.locale.value
  });
  const pinia = createPinia();

  const app = createApp(App);

  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost'],
      replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'local'
    });
  }

  app.provide(API_INJECTION_KEY, apiClient);
  app.use(router);
  app.use(i18n);
  app.use(pinia);
  app.use(autoAnimatePlugin);

  app.use(VueQueryPlugin, { queryClient });
  try {
    await router.isReady();
    await apiClient.authService.init();
    await queryClient.prefetchQuery(
      queryKeys.FEATURE_FLAGS,
      apiClient.featureFlagService.getFeatureFlags
    );

    const redirectUrl = getRedirectUrl(
      router.currentRoute.value,
      router.currentRoute.value.query.from,
      apiClient.authService.isAuthenticated()
    );

    if (redirectUrl) {
      await router.push(redirectUrl as any);
    }
  } catch (err) {
    console.error('error before mount', err);
  } finally {
    app.mount('#app');
  }
}

main();
