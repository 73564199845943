import type { FeatureFlagsResponse } from '@samsys/shared';
import type { HttpService } from './http.service';

export const createFeatureFlagService = ({
  httpService
}: {
  httpService: HttpService;
}) => {
  return {
    getFeatureFlags() {
      return httpService.get<FeatureFlagsResponse>('/api/v2/feature-flags');
    }
  };
};
