import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferFlatRequest } from '@/utils/ts-rest';

export type CreateFavoriteFilterServiceOptions = { tsRestClient: TsRestClient };

export const createFavoriteFilterService = ({
  tsRestClient
}: CreateFavoriteFilterServiceOptions) => {
  return {
    getByKey({ key }: InferFlatRequest<typeof contract.favoriteFilters.byKey>) {
      return apiHandler(tsRestClient.favoriteFilters.byKey, {
        params: { key }
      });
    },

    add(body: InferFlatRequest<typeof contract.favoriteFilters.add>) {
      return apiHandler(tsRestClient.favoriteFilters.add, {
        body
      });
    },

    update({
      id,
      ...body
    }: InferFlatRequest<typeof contract.favoriteFilters.update>) {
      return apiHandler(tsRestClient.favoriteFilters.update, {
        body,
        params: { id }
      });
    },

    remove({
      filterId
    }: InferFlatRequest<typeof contract.favoriteFilters.delete>) {
      return apiHandler(tsRestClient.favoriteFilters.delete, {
        params: { filterId }
      });
    }
  };
};
