import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferBody, type InferQuery } from '@/utils/ts-rest';

export type CreateMaintenanceActionServiceOptions = {
  tsRestClient: TsRestClient;
};

export const createMaintenanceActionService = ({
  tsRestClient
}: CreateMaintenanceActionServiceOptions) => {
  return {
    create(body: InferBody<typeof contract.maintenanceAction.create>) {
      return apiHandler(tsRestClient.maintenanceAction.create, { body });
    },

    delete(machineId: string) {
      return apiHandler(tsRestClient.maintenanceAction.delete, {
        params: { machineId }
      });
    },

    getAllByMachine({
      machineId,
      ...query
    }: InferQuery<typeof contract.maintenanceAction.byMachine> & {
      machineId: string;
    }) {
      return apiHandler(tsRestClient.maintenanceAction.byMachine, {
        params: { machineId },
        query
      });
    }
  };
};
