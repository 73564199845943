import {
  IO_EVENTS,
  type ClientToServerEvents,
  type contract,
  type ServerToClientEvents
} from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferParams
} from '@/utils/ts-rest';
import type { Socket } from 'socket.io-client';

export const createActivityBookingService = ({
  tsRestClient,
  socket
}: {
  tsRestClient: TsRestClient;
  socket: Socket<ServerToClientEvents, ClientToServerEvents>;
}) => {
  return {
    subscribe() {
      socket.emit(IO_EVENTS.CLIENT.SUBSCRIBE_TO_BOOKINGS);

      return () => {
        socket.emit(IO_EVENTS.CLIENT.UNSUBSCRIBE_TO_BOOKINGS);
      };
    },

    create(body: InferFlatRequest<typeof contract.activityBooking.create>) {
      return apiHandler(tsRestClient.activityBooking.create, { body });
    },

    createSimple(
      body: InferBody<typeof contract.activityBooking.createSimple>
    ) {
      return apiHandler(tsRestClient.activityBooking.createSimple, { body });
    },

    update({
      bookingId,
      ...body
    }: InferFlatRequest<typeof contract.activityBooking.update>) {
      return apiHandler(tsRestClient.activityBooking.update, {
        params: { bookingId },
        body
      });
    },

    updateSimple({
      id,
      ...body
    }: InferFlatRequest<typeof contract.activityBooking.updateSimple>) {
      return apiHandler(tsRestClient.activityBooking.updateSimple, {
        params: { id },
        body
      });
    },

    validate({
      bookingId
    }: InferParams<typeof contract.activityBooking.validate>) {
      return apiHandler(tsRestClient.activityBooking.validate, {
        params: { bookingId }
      });
    },

    async getAll(query: InferFlatRequest<typeof contract.activityBooking.all>) {
      const response = await apiHandler(tsRestClient.activityBooking.all, {
        query
      });

      response.items.forEach(item => {
        if (item.startDate) {
          item.startDate = new Date(item.startDate);
        }
        if (item.endDate) {
          item.endDate = new Date(item.endDate);
        }
      });

      return response;
    },

    async getAttributions() {
      const bookings = await apiHandler(
        tsRestClient.activityBooking.attributions,
        {}
      );

      bookings.forEach(item => {
        if (item.startDate) {
          item.startDate = new Date(item.startDate);
        }
        if (item.endDate) {
          item.endDate = new Date(item.endDate);
        }
      });

      return bookings;
    },

    delete({
      bookingId
    }: InferFlatRequest<typeof contract.activityBooking.delete>) {
      return apiHandler(tsRestClient.activityBooking.delete, {
        params: { bookingId }
      });
    },

    getConflicts(
      body: InferFlatRequest<typeof contract.activityBooking.conflicts>
    ) {
      return apiHandler(tsRestClient.activityBooking.conflicts, { body });
    }
  };
};
