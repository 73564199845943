<script setup lang="ts">
import type { Nullable } from '@samsys/shared';

const props = defineProps<{
  error: Nullable<Error>;
  title?: string;
  reloadLabel?: string;
}>();
const emit = defineEmits<{ clear: [] }>();

const isDev = computed(() => import.meta.env.DEV);

const { t } = useI18n();
</script>

<template>
  <UiCenter v-if="props.error" class="error-boundary">
    <UiSurface>
      <UiIcon size="xxl" icon="material-symbols:error" />

      <h2>{{ props.title ?? t('app.error.title') }}</h2>
      <p>{{ t('app.error.text') }}</p>

      <pre v-if="isDev">{{ props.error.stack }}</pre>

      <UiButton @click="emit('clear')">
        {{ props.reloadLabel ?? t('common.retry') }}
      </UiButton>
    </UiSurface>
  </UiCenter>

  <slot v-else />
</template>

<style scoped lang="postcss">
.error-boundary {
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--size-4);
  }
  & .ui-icon {
    color: var(--error);
  }
}

h2 {
  font-size: var(--font-size-5);
  max-inline-size: 100%;
}

pre {
  background-color: var(--surface-2);
  padding: var(--size-3);
  max-inline-size: var(--size-md);
  overflow-x: auto;
}
</style>
