import type { Nullable } from '@samsys/shared';
// import * as Sentry from '@sentry/vue';

export const useErrorCatcher = () => {
  const error = ref<Nullable<Error>>(null);

  onErrorCaptured(err => {
    // if (me.value) {
    //   Sentry.setUser({ id: me.value.id, email: me.value.email });
    // }
    // Sentry.setTag('locale', locale.value);
    // Sentry.setTag('environment', import.meta.env.SENTRY_ENVIRONMENT ?? 'local');
    // Sentry.captureException(err);

    console.error(err);
    error.value = err;

    return false;
  });

  return error;
};
