import type { PaginatedDelegatedUserDto } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler } from '@/utils/ts-rest';

export type CreateMeServiceOptions = {
  tsRestClient: TsRestClient;
};
export type MeService = ReturnType<typeof createMeService>;

export const ME_ENDPOINT = '/api/v2/me/';
export const createMeService = ({ tsRestClient }: CreateMeServiceOptions) => {
  return {
    me() {
      return apiHandler(tsRestClient.me.me, {});
    },

    getDelegatedUsers(query: PaginatedDelegatedUserDto) {
      return apiHandler(tsRestClient.me.delegated, { query });
    },

    getFavoriteDashboards() {
      return apiHandler(tsRestClient.favoriteDashbooard.all, {});
    },

    updateFavoriteDashboards(dashboardIds: string[]) {
      return apiHandler(tsRestClient.favoriteDashbooard.update, {
        body: { dashboardIds }
      });
    },

    generateApiKey() {
      return apiHandler(tsRestClient.me.generateApiKey, {});
    },

    revokeApiKey() {
      return apiHandler(tsRestClient.me.revokeApiKey, {});
    }
  };
};
