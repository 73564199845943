import type { contract } from '@samsys/shared';
import { apiHandler, type InferBody, type InferQuery } from '@/utils/ts-rest';
import type { TsRestClient } from './ts-rest.service';

export type CreateNotificationServiceOptions = { tsRestClient: TsRestClient };

export const ENDPOINT = '/notifications';
export const createNotificationService = ({
  tsRestClient
}: CreateNotificationServiceOptions) => {
  return {
    getAll(query: InferQuery<typeof contract.notification.all>) {
      return apiHandler(tsRestClient.notification.all, { query });
    },

    markAsSeen(body: InferBody<typeof contract.notification.markAsSeen>) {
      return apiHandler(tsRestClient.notification.markAsSeen, { body });
    },

    enablePush(body: InferBody<typeof contract.notification.enablePush>) {
      return apiHandler(tsRestClient.notification.enablePush, { body });
    },

    disablePush(query: InferQuery<typeof contract.notification.disablePush>) {
      return apiHandler(tsRestClient.notification.disablePush, { query });
    }
  };
};
