import type { AnyObject, ErrorKind, ErrorResponse } from '@samsys/shared';
import type {
  HTTPStatusCode,
  ErrorHttpStatusCode,
  AppRoute,
  ServerInferRequest
} from '@ts-rest/core';
import { isObject } from '@turf/turf';

export const isApiErrorResponse = <T extends { status: HTTPStatusCode }>(
  response: T
): response is T & { status: ErrorHttpStatusCode } => {
  return response.status < 200 || response.status > 207;
};

export const isApiError = (err: unknown): err is ErrorResponse => {
  if (!isObject(err)) return false;
  // @FIXME why is type narrowing not working here ?
  return (
    'message' in (err as any) &&
    'kind' in (err as any) &&
    'cause' in (err as any)
  );
};

export const isApiErrorOfKind = <TKind extends ErrorKind>(
  err: unknown,
  kind: TKind
): err is ErrorResponse & { kind: TKind } => {
  if (!isApiError(err)) return false;

  return err.kind === kind;
};

type GenericApiFunction = (
  ...args: any[]
) => Promise<{ status: HTTPStatusCode; body: AnyObject }>;

export const apiHandler = <TFn extends GenericApiFunction>(
  fn: TFn,
  args: Omit<Parameters<TFn>[0], 'headers'> // headers are provided via an ofetch interceptor
): Promise<
  Exclude<Awaited<ReturnType<TFn>>, { status: ErrorHttpStatusCode }>['body']
> => {
  return fn(args).then(response => {
    if (isApiErrorResponse(response)) {
      throw response.body;
    }

    return response.body;
  });
};

export type InferBody<
  T extends AppRoute & { method: 'POST' | 'PATCH' | 'PUT' }
> = ServerInferRequest<T>['body'];

export type InferParams<T extends AppRoute> = ServerInferRequest<T>['params'];

export type InferQuery<T extends AppRoute> = ServerInferRequest<T>['query'];

export type InferFlatRequest<T extends AppRoute> = T extends AppRoute & {
  method: 'POST' | 'PATCH' | 'PUT';
}
  ? InferParams<T> & InferBody<T>
  : InferParams<T> & InferQuery<T>;
