import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferBody } from '@/utils/ts-rest';

export const createTsDatadefService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getByTargets(body: InferBody<typeof contract.tsDatadef.byWidgetTarget>) {
      return apiHandler(tsRestClient.tsDatadef.byWidgetTarget, { body });
    }
  };
};
