import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferQuery } from '@/utils/ts-rest';

export type CreateMaintenanceAlertServiceOptions = {
  tsRestClient: TsRestClient;
};

export const createMaintenanceAlertService = ({
  tsRestClient
}: CreateMaintenanceAlertServiceOptions) => {
  return {
    getAll(query: InferQuery<typeof contract.maintenanceAlert.all>) {
      return apiHandler(tsRestClient.maintenanceAlert.all, { query });
    }
  };
};
