<script setup lang="ts">
const route = useRoute();
</script>

<template>
  <RouterView v-slot="{ Component }">
    <transition :name="route.meta.transition as string ?? ''" mode="out-in">
      <component :is="Component" :key="route.path" />
    </transition>
  </RouterView>
</template>

<style lang="postcss">
body:has(
    :is(
        .mobile-slide-left-enter-active,
        .mobile-slide-left-leave-active,
        .mobile-slide-right-enter-active,
        .mobile-slide-right-leave-active
      )
  ) {
  max-width: 100vw;
  overflow-x: hidden;
}
</style>

<style scoped lang="postcss">
@import '@/styles/medias.css';

.fullpage-loader {
  height: 100vh;
  height: 100dvh;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s ease;
}

.mobile-slide-left-enter-active,
.mobile-slide-left-leave-active,
.mobile-slide-right-enter-active,
.mobile-slide-right-leave-active {
  transition: all 0.3s ease;
}

.slide-right-enter-from {
  transform: translateX(-25%) scale(0.75);
  opacity: 0;
}

.slide-right-leave-to {
  transform: translateX(25%) scale(0.75);
  opacity: 0;
}

.slide-left-enter-from {
  transform: translateX(25%) scale(0.75);
  opacity: 0;
}

.slide-left-leave-to {
  transform: translateX(-25%) scale(0.75);
  opacity: 0;
}

@media (--md-n-below) {
  .mobile-slide-left-enter-from {
    transform: translateX(100%);
    opacity: 0;
  }

  .mobile-slide-left-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }

  .mobile-slide-right-enter-from {
    transform: translateX(-100%);
    opacity: 0;
  }

  .mobile-slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
