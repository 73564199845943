import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferBody, type InferQuery } from '@/utils/ts-rest';

export type CreateMaintenanceProfileServiceOptions = {
  tsRestClient: TsRestClient;
};

export const createMaintenanceProfileService = ({
  tsRestClient
}: CreateMaintenanceProfileServiceOptions) => {
  return {
    async getAll(query: InferQuery<typeof contract.maintenanceProfile.all>) {
      return apiHandler(tsRestClient.maintenanceProfile.all, { query });
    },

    create(body: InferBody<typeof contract.maintenanceProfile.create>) {
      return apiHandler(tsRestClient.maintenanceProfile.create, { body });
    },

    delete(profileId: string) {
      return apiHandler(tsRestClient.maintenanceProfile.delete, {
        params: { profileId }
      });
    },

    update({
      profileId,
      ...body
    }: InferBody<typeof contract.maintenanceProfile.update> & {
      profileId: string;
    }) {
      return apiHandler(tsRestClient.maintenanceProfile.update, {
        params: { profileId },
        body
      });
    }
  };
};
