import { isPlainObject } from '@samsys/shared';
import {
  QueryClient,
  type Query,
  type QueryKey,
  type QueryOptions,
  type MutationKey
} from '@tanstack/vue-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30_000,
      refetchOnWindowFocus: false,
      retry: false,
      networkMode: 'offlineFirst'
    },
    mutations: {
      networkMode: 'offlineFirst'
    }
  }
});

export function hashKey(queryKey: QueryKey | MutationKey): string {
  return JSON.stringify(queryKey, (_, val) =>
    isPlainObject(val)
      ? Object.keys(val)
          .sort()
          .reduce((result, key) => {
            result[key] = val[key];
            return result;
          }, {} as any)
      : val
  );
}

export function hashQueryKeyByOptions<TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  options?: QueryOptions<any, any, any, TQueryKey>
): string {
  const hashFn = options?.queryKeyHashFn || hashKey;
  return hashFn(queryKey);
}

/**
 * Checks if key `b` partially matches with key `a`.
 */
export function partialMatchKey(a: QueryKey, b: QueryKey): boolean;
export function partialMatchKey(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    return !Object.keys(b).some(key => !partialMatchKey(a[key], b[key]));
  }

  return false;
}

// copy-pasted from tanstack query codebase, because they do not specify it on their package.json's "exports" specifier so we cant import it
export function matchQuery(
  filters: any,
  query: Query<any, any, any, any>
): boolean {
  const {
    type = 'all',
    exact,
    fetchStatus,
    predicate,
    queryKey,
    stale
  } = filters;

  if (queryKey) {
    if (exact) {
      if (query.queryHash !== hashQueryKeyByOptions(queryKey, query.options)) {
        return false;
      }
    } else if (!partialMatchKey(query.queryKey, queryKey)) {
      return false;
    }
  }

  if (type !== 'all') {
    const isActive = query.isActive();
    if (type === 'active' && !isActive) {
      return false;
    }
    if (type === 'inactive' && isActive) {
      return false;
    }
  }

  if (typeof stale === 'boolean' && query.isStale() !== stale) {
    return false;
  }

  if (
    typeof fetchStatus !== 'undefined' &&
    fetchStatus !== query.state.fetchStatus
  ) {
    return false;
  }

  if (predicate && !predicate(query)) {
    return false;
  }

  return true;
}

export const matchQueryByQueryKeys = (
  query: Query<any, any, any, any>,
  ...queryKeys: QueryKey[]
) => {
  return queryKeys.some(key =>
    matchQuery({ queryKey: key.map(segment => toValue(segment)) }, query)
  );
};

export const matchByQueryKeyPredicate =
  (...queryKeys: QueryKey[]) =>
  (query: Query) =>
    matchQueryByQueryKeys(query, ...queryKeys);
