import type { AnyObject, LoginDto, Nullable } from '@samsys/shared';
import type { HttpService } from './http.service';
import jwtDecode from 'jwt-decode';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler } from '@/utils/ts-rest';

type JwtPayload = {
  identity: string;
  iat: number;
  exp: number;
};

export const REFRESH_ENDPOINT = '/api/v2/auth/refresh';
export const LOGIN_ENDPOINT = '/api/v2/auth/login';
export const LOGOUT_ENDPOINT = '/api/v2/auth/logout';
export const AUTH_HEADER = 'authorization';
export const REFRESH_TOKEN_LOCALSTORAGE_KEY = 'refresh-token';

type CreateAuthServiceOptions = {
  httpService: HttpService;
  tsRestClient: TsRestClient;
};

export type AuthService = ReturnType<typeof createAuthService>;

export const createAuthService = ({
  httpService,
  tsRestClient: client
}: CreateAuthServiceOptions) => {
  let accessToken: Nullable<string> = null;
  const getBearer = (token: string) => (token ? `JWT ${token}` : '');

  const addHeaders = () => {
    httpService.onRequest(config => {
      if (!accessToken) return;

      const headers = config.options.headers as AnyObject;

      if (!headers[AUTH_HEADER]) {
        headers[AUTH_HEADER] = getBearer(accessToken);
      }
    });
  };

  const handle401 = () => {
    httpService.onResponse(({ response }) => {
      if (response.status === 401) {
        if (import.meta.env.PROD) {
          localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
        }
        accessToken = null;
      }
    });
  };

  const EXPIRY_BUFFER_IN_SECONDS = 15;
  const checkJwtExpiration = (jwt: string) => {
    const { exp } = jwtDecode<JwtPayload>(jwt);
    const now = new Date();
    const expirationDate = new Date((exp - EXPIRY_BUFFER_IN_SECONDS) * 1000); // exp is in seconds
    return now.getTime() > expirationDate.getTime();
  };

  const refreshJwt = async (req?: RequestInfo) => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
    if (!refreshToken) return;

    try {
      const response = await apiHandler(client.auth.refresh, {
        headers: {
          [AUTH_HEADER]: getBearer(refreshToken)
        }
      });
      accessToken = response.token;
      if (req) {
      }

      return accessToken;
    } catch (err) {
      if (!req) throw err;
    }
  };

  const handleRefreshToken = () => {
    // keeping track of the refresh promise for deduping
    // we want to dedupe the refresh token call to ensure it's only called once when needed
    let ongoingRefreshPromise: Nullable<Promise<void>>;

    const refreshJwtIfExpired = async (req: RequestInfo) => {
      if (!accessToken) return;

      const isExpired = checkJwtExpiration(accessToken);
      if (!isExpired) return;

      await refreshJwt(req);
    };

    httpService.onRequest(async config => {
      try {
        const pathname = config.request.toString().startsWith('http')
          ? new URL(config.request.toString()).pathname
          : config.request.toString();

        const shouldSkip = [LOGIN_ENDPOINT, REFRESH_ENDPOINT].includes(
          pathname
        );

        if (import.meta.env.PROD) {
          console.log(pathname, shouldSkip);
        }
        if (shouldSkip) return;

        if (config.options.headers) {
          const headers = config.options.headers as AnyObject;
          if (headers[AUTH_HEADER]) {
            return;
          }
        }

        if (!ongoingRefreshPromise) {
          ongoingRefreshPromise = refreshJwtIfExpired(config.request);
        }

        await ongoingRefreshPromise;
      } catch (err) {
        console.error('Error trying to refresh token', err);
      } finally {
        ongoingRefreshPromise = null;
      }
    });
  };

  return {
    async init() {
      handleRefreshToken();
      addHeaders();
      handle401();
      try {
        return await refreshJwt();
      } catch (err) {
        console.error('Error refreshing JWT', err);
      }
    },

    async login(body: LoginDto) {
      const response = await apiHandler(client.auth.login, { body });

      accessToken = response.token;
      localStorage.setItem(
        REFRESH_TOKEN_LOCALSTORAGE_KEY,
        response.refreshToken
      );

      return response;
    },

    isAuthenticated() {
      return !!accessToken;
    },

    getToken() {
      return accessToken;
    },

    async logout(webpushEndpoint?: string | undefined) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
      if (!refreshToken) {
        accessToken = null;
        return;
      }

      const response = await apiHandler(client.auth.logout, {
        body: { webpushEndpoint }
      });

      accessToken = null;
      localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

      return response;
    },

    refreshJwt,

    async loginWithCode(code: string) {
      const response = await apiHandler(client.auth.loginCode, {
        body: { code }
      });

      accessToken = response.token;
      localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

      return response;
    }
  };
};
