import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferFlatRequest } from '@/utils/ts-rest';

export const createActivityTemplateService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getAll() {
      return apiHandler(tsRestClient.activityTemplate.all, {});
    },

    async create(
      body: InferFlatRequest<typeof contract.activityTemplate.create>
    ) {
      return apiHandler(tsRestClient.activityTemplate.create, { body });
    },

    async update({
      templateId,
      ...body
    }: InferFlatRequest<typeof contract.activityTemplate.update>) {
      return apiHandler(tsRestClient.activityTemplate.update, {
        params: { templateId },
        body
      });
    },

    delete(templateId: string) {
      return apiHandler(tsRestClient.activityTemplate.delete, {
        params: { templateId }
      });
    }
  };
};
