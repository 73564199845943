import { contract, type BillingInvoiceResponse } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferParams } from '@/utils/ts-rest';
import type { HttpService } from './http.service';

export const createBillingService = ({
  tsRestClient,
  httpService
}: {
  tsRestClient: TsRestClient;
  httpService: HttpService;
}) => {
  return {
    addUsers({ accountId, users }: { accountId: string; users: string[] }) {
      return apiHandler(tsRestClient.billingAccount.addUsers, {
        params: { id: accountId },
        body: { users }
      });
    },

    removeUser(params: InferParams<typeof contract.billingAccount.removeUser>) {
      return apiHandler(tsRestClient.billingAccount.removeUser, { params });
    },

    getMandateCreationUrl(id: string) {
      return apiHandler(tsRestClient.billingAccount.gocardlessUrl, {
        params: { id }
      });
    },

    finalizeMandateCreation(redirectFlowId: string) {
      return apiHandler(tsRestClient.billingAccount.gocardlessFinalize, {
        body: { redirectFlowId }
      });
    },

    getMandateUrl(accountId: string) {
      return apiHandler(tsRestClient.billingAccount.mandateUrl, {
        params: { id: accountId }
      });
    },

    async downloadInvoice(invoice: BillingInvoiceResponse) {
      const url = await httpService.get<string>(
        `/api/v2/invoices/${invoice.id}`,
        {
          responseType: 'text'
        }
      );

      const aElement = document.createElement('a');

      aElement.setAttribute('download', invoice.name + '.pdf');
      aElement.setAttribute('target', '_blank');

      aElement.href = url;
      aElement.click();
      aElement.remove();
    }
  };
};
