<script setup lang="ts">
defineOptions({
  name: 'UiCenter'
});
const props = withDefaults(
  defineProps<{ as?: string; direction?: 'row' | 'column' }>(),
  { as: 'div', direction: 'column' }
);
</script>

<template>
  <component :is="props.as" class="ui-center">
    <slot />
  </component>
</template>

<style scoped>
.ui-center {
  height: 100%;
  display: flex;
  flex-direction: v-bind('props.direction');
  align-items: center;
  justify-content: center;
}
</style>
