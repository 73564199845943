import z from 'zod';

const configSchema = z.object({
  API_URL: z.string(),
  MAPBOX_ACCESS_TOKEN: z.string()
});

export const config = {
  API_URL: import.meta.env.VITE_API_URL,
  MAPBOX_ACCESS_TOKEN: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
};

export const validateConfig = () => {
  const result = configSchema.safeParse(config);

  if (!result.success) {
    console.error('INVALID APP CONFIG');
    console.error(result.error);
  }
};
