import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferParams,
  type InferQuery
} from '@/utils/ts-rest';

export const createWidgetService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getAll(query: InferQuery<typeof contract.widget.all>) {
      return apiHandler(tsRestClient.widget.all, { query });
    },

    getAllShared(query: InferQuery<typeof contract.widget.shared>) {
      return apiHandler(tsRestClient.widget.shared, { query });
    },

    getById(widgetId: string) {
      return apiHandler(tsRestClient.widget.byId, { params: { widgetId } });
    },

    getData({
      widgetId,
      ...query
    }: InferFlatRequest<typeof contract.widget.datas>) {
      return apiHandler(tsRestClient.widget.datas, {
        params: { widgetId },
        query
      });
    },

    create(body: InferBody<typeof contract.widget.create>) {
      return apiHandler(tsRestClient.widget.create, { body });
    },

    delete(params: InferParams<typeof contract.widget.delete>) {
      return apiHandler(tsRestClient.widget.delete, { params });
    },

    update({
      widgetId,
      ...body
    }: InferFlatRequest<typeof contract.widget.update>) {
      return apiHandler(tsRestClient.widget.update, {
        params: { widgetId },
        body
      });
    }
  };
};
