import type { PingResponse } from '@samsys/shared';
import type { HttpService } from './http.service';

export type CreatePingServiceOptions = { httpService: HttpService };

export const createPingService = ({
  httpService
}: CreatePingServiceOptions) => {
  return {
    ping() {
      return httpService.get<PingResponse>('/ping');
    }
  };
};
