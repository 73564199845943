import {
  type ServerToClientEvents,
  type ClientToServerEvents,
  IO_EVENTS,
  contract
} from '@samsys/shared';
import type { Socket } from 'socket.io-client';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferQuery
} from '@/utils/ts-rest';
import type { TsRestClient } from './ts-rest.service';

export const createDashboardService = ({
  tsRestClient,
  socket
}: {
  tsRestClient: TsRestClient;
  socket: Socket<ServerToClientEvents, ClientToServerEvents>;
}) => {
  return {
    subscribe(id: string) {
      socket.emit(IO_EVENTS.CLIENT.SUBSCRIBE_TO_DASHBOARD, id);

      return () => {
        socket.emit(IO_EVENTS.CLIENT.UNSUBSCRIBE_TO_DASHBOARD, id);
      };
    },

    getAll(query: InferQuery<typeof contract.dashboard.all>) {
      return apiHandler(tsRestClient.dashboard.all, { query });
    },

    getById(dashboardId: string) {
      return apiHandler(tsRestClient.dashboard.byId, {
        params: { dashboardId }
      });
    },

    create(body: InferBody<typeof contract.dashboard.create>) {
      return apiHandler(tsRestClient.dashboard.create, { body });
    },

    update({
      dashboardId,
      ...body
    }: InferFlatRequest<typeof contract.dashboard.update>) {
      return apiHandler(tsRestClient.dashboard.update, {
        params: { dashboardId },
        body
      });
    },

    delete(dashboardId: string) {
      return apiHandler(tsRestClient.dashboard.delete, {
        params: { dashboardId }
      });
    },

    clone(dashboardId: string) {
      return apiHandler(tsRestClient.dashboard.clone, {
        params: { dashboardId }
      });
    }
  };
};
