import { isString } from '@samsys/shared';

export const getRedirectUrl = (
  to: any,
  from: any,
  isAuthenticated: boolean
) => {
  if (!to.meta.public && !isAuthenticated) {
    return {
      name: 'Login',
      query: { from: encodeURIComponent(to.fullPath) }
    };
  }

  if (to.meta.public && isAuthenticated && to.name === 'loginCode') {
    return;
  }

  if (to.meta.public && isAuthenticated) {
    if (isString(from)) return decodeURIComponent(from);
    return {
      name: 'Home'
    };
  }
};

const isRedirecting = ref(false);

export const useAuthGuard = () => {
  const api = useApi();
  const router = useRouter();

  api.httpService.onResponseError(ctx => {
    if (isRedirecting.value) return;

    if (ctx.response.status === 401) {
      if (router.currentRoute.value.meta.public) {
        return;
      }
      isRedirecting.value = true;
      router.replace({ name: 'Login' });
    }
  });

  const { authService } = useApi();

  router.beforeEach((to, from, next) => {
    const redirectUrl = getRedirectUrl(
      to,
      from.query.from,
      authService.isAuthenticated()
    );

    if (redirectUrl) return next(redirectUrl as any);

    return next();
  });

  router.afterEach(() => {
    isRedirecting.value = false;
  });
};
