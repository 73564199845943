import type {
  ClientToServerEvents,
  ServerToClientEvents
} from '@samsys/shared';
import type { HttpService } from './http.service';
import { LOGOUT_ENDPOINT, type AuthService } from './auth.service';
import { io, type Socket } from 'socket.io-client';
import { config } from '@/config';
import { ME_ENDPOINT } from './me.service';
import { isString } from 'lodash-es';

export const createWebsocketService = ({
  httpService,
  authService
}: {
  httpService: HttpService;
  authService: AuthService;
}) => {
  const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
    config.API_URL,
    {
      autoConnect: false,
      transports: ['websocket'],
      auth: cb => cb({ token: authService.getToken() })
    }
  );

  httpService.onResponse(ctx => {
    if (!isString(ctx.request)) return;

    const url = new URL(ctx.request);
    if (url.pathname === ME_ENDPOINT && !socket.connected) {
      socket.connect();
    }

    if (url.pathname === LOGOUT_ENDPOINT || ctx.response.status === 401) {
      if (socket.connected) {
        socket.disconnect();
      }
    }
  });
  return socket;
};
