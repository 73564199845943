import {
  IO_EVENTS,
  type ClientToServerEvents,
  type ServerToClientEvents,
  contract
} from '@samsys/shared';
import type { Socket } from 'socket.io-client';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferParams,
  type InferQuery
} from '@/utils/ts-rest';

export type CreateDataboxServiceOptions = {
  tsRestClient: TsRestClient;
  socket: Socket<ServerToClientEvents, ClientToServerEvents>;
};

export const createDataboxService = ({
  tsRestClient,
  socket
}: CreateDataboxServiceOptions) => {
  return {
    subscribeToLatestDatas(ids?: string[]) {
      socket.emit(IO_EVENTS.CLIENT.SUBSCRIBE_TO_DATABOX, ids ?? 'all');

      return () => {
        socket.emit(IO_EVENTS.CLIENT.UNSUBSCRIBE_TO_DATABOX, ids ?? 'all');
      };
    },

    getAll(query: InferQuery<typeof contract.databox.all>) {
      return apiHandler(tsRestClient.databox.all, { query });
    },

    getById(params: InferParams<typeof contract.databox.byId>) {
      return apiHandler(tsRestClient.databox.byId, { params });
    },

    getAlerts(params: InferParams<typeof contract.databox.alerts>) {
      return apiHandler(tsRestClient.databox.alerts, { params });
    },

    getDatadef(params: InferParams<typeof contract.databox.tsDatadefs>) {
      return apiHandler(tsRestClient.databox.tsDatadefs, { params });
    },

    getAvailableHashtags() {
      return apiHandler(tsRestClient.databox.hashtags, {});
    },

    editHashtags({
      databoxId,
      ...body
    }: InferFlatRequest<typeof contract.databox.editHashtags>) {
      return apiHandler(tsRestClient.databox.editHashtags, {
        params: { databoxId },
        body
      });
    },

    editAlerts({
      databoxId,
      ...body
    }: InferFlatRequest<typeof contract.databox.editAlerts>) {
      return apiHandler(tsRestClient.databox.editAlerts, {
        params: { databoxId },
        body
      });
    },

    async shareAlerts({
      databoxesIds,
      alerts
    }: {
      databoxesIds: string[];
      alerts: InferBody<typeof contract.databox.editAlerts>[];
    }) {
      await Promise.all(
        databoxesIds.map(async databoxId => {
          for (const alert of alerts) {
            await apiHandler(tsRestClient.databox.editAlerts, {
              params: { databoxId },
              body: alert
            });
          }
        })
      );

      return { success: true };
    },

    async getTsDatas({
      databoxId,
      ...body
    }: InferFlatRequest<typeof contract.databox.tsDatas>) {
      return apiHandler(tsRestClient.databox.tsDatas, {
        params: { databoxId },
        body
      });
    },

    export(opts: InferBody<typeof contract.databox.export>) {
      return apiHandler(tsRestClient.databox.export, { body: opts });
    },

    changeOwnership(body: InferBody<typeof contract.databox.ownership>) {
      return apiHandler(tsRestClient.databox.ownership, { body });
    }
  };
};
