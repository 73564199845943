import { config } from '@/config';
import type { AnyObject } from '@samsys/shared';
import { ofetch, type FetchOptions, type $Fetch } from 'ofetch';

const requestInterceptors = new Set<FetchOptions['onRequest']>();
const requestErrorInterceptors = new Set<FetchOptions['onRequestError']>();
const responseInterceptors = new Set<FetchOptions['onResponse']>();
const responseErrorInterceptors = new Set<FetchOptions['onResponseError']>();

type HttpOptions = Omit<FetchOptions<'json' | 'blob' | 'text'>, 'method'>;

const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';

export type HttpService = {
  fetch: $Fetch;
  get<T>(url: string, options?: HttpOptions): Promise<T>;
  post<T>(url: string, options?: HttpOptions): Promise<T>;
  put<T>(url: string, options?: HttpOptions): Promise<T>;
  delete<T>(url: string, options?: HttpOptions): Promise<T>;
  onRequest(listener: FetchOptions['onRequest']): void;
  onRequestError(listener: FetchOptions['onRequestError']): void;
  onResponse(listener: FetchOptions['onResponse']): void;
  onResponseError(listener: FetchOptions['onResponseError']): void;
};

export const createHttpService = ({
  getLang
}: {
  getLang: () => string;
}): HttpService => {
  const http = ofetch.create?.({
    retry: false,
    credentials: 'include',
    baseURL: config.API_URL,
    async onRequest(ctx) {
      if (!ctx.options.headers) {
        ctx.options.headers = {};
      }

      const headers = ctx.options.headers as AnyObject;
      headers[ACCEPT_LANGUAGE_HEADER] = getLang();

      for (const cb of requestInterceptors.values()) {
        await cb?.(ctx);
      }
    },
    async onRequestError(ctx) {
      for (const cb of requestErrorInterceptors.values()) {
        await cb?.(ctx);
      }
    },
    async onResponse(ctx) {
      for (const cb of responseInterceptors.values()) {
        await cb?.(ctx);
      }
    },
    async onResponseError(ctx) {
      for (const cb of responseErrorInterceptors.values()) {
        await cb?.(ctx);
      }
    }
  });

  const createHttpCaller =
    (method: 'GET' | 'POST' | 'PUT' | 'DELETE') =>
    async <T>(url: string, options: HttpOptions = {}) => {
      const result = await http(url, { method, ...options });

      return result as T;
    };

  return {
    fetch: http,
    get: createHttpCaller('GET'),
    post: createHttpCaller('POST'),
    put: createHttpCaller('PUT'),
    delete: createHttpCaller('DELETE'),
    onRequest(listener: FetchOptions['onRequest']) {
      requestInterceptors.add(listener);
    },
    onRequestError(listener: FetchOptions['onRequestError']) {
      requestErrorInterceptors.add(listener);
    },
    onResponse(listener: FetchOptions['onResponse']) {
      responseInterceptors.add(listener);
    },
    onResponseError(listener: FetchOptions['onResponseError']) {
      responseErrorInterceptors.add(listener);
    }
  };
};
