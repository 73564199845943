import {
  apiHandler,
  type InferFlatRequest,
  type InferQuery
} from '@/utils/ts-rest';
import type { TsRestClient } from './ts-rest.service';
import type { contract } from '@samsys/shared';

export const createStaticDashboardService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getAll(query: InferQuery<typeof contract.staticDashboard.all>) {
      return apiHandler(tsRestClient.staticDashboard.all, { query });
    },

    getById(id: string) {
      return apiHandler(tsRestClient.staticDashboard.byId, {
        params: { id }
      });
    },

    update({
      id,
      ...body
    }: InferFlatRequest<typeof contract.staticDashboard.update>) {
      return apiHandler(tsRestClient.staticDashboard.update, {
        params: { id },
        body
      });
    },

    clone({
      id,
      ...body
    }: InferFlatRequest<typeof contract.staticDashboard.clone>) {
      return apiHandler(tsRestClient.staticDashboard.clone, {
        params: { id },
        body
      });
    },

    share({
      id,
      ...body
    }: InferFlatRequest<typeof contract.staticDashboard.share>) {
      return apiHandler(tsRestClient.staticDashboard.share, {
        params: { id },
        body
      });
    }
  };
};
